import { useLoaderData } from "@remix-run/react"
import { redirect, type LoaderFunctionArgs } from "@remix-run/node"
import { getOffset } from "../getOffset"
import { getClientIPAddress } from "remix-utils/get-client-ip-address"

export async function loader({ request }: LoaderFunctionArgs) {
  let ip = request.headers.get("CF-Connecting-IP")
  if (ip === null) ip = getClientIPAddress(request)
  const queryString = new URL(request.url).searchParams
  const { offset, icecastURL } = await getOffset(ip)

  if (queryString.get("embed") == "true") {
    throw redirect(icecastURL)
  }

  return { offset, icecastURL, ip }
}

export default function Index() {
  const { icecastURL } = useLoaderData<typeof loader>()

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls={true} autoPlay={true}>
      <source src={icecastURL} type="audio/mpeg"></source>
    </audio>
  )
}
